.card-box {
    padding: 10px 30px;
    position: relative;
    margin: .5rem .5rem 1rem;
    background-color: #fff;
    transition: box-shadow .25s;
    border-radius: 25px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.card-box p {
    font-size: 20px;
}