.notification_btn {
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    outline: none; /* Remove outline */
    background-color: #2d593c; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
    border: solid 1px #0E4721;
}

.notification_btn > svg {
    margin: .25rem;
}

.notification_btn:hover {
    color: #ffffff;
}

.amount {
    position: absolute;
    top: -13px;
    right: -8px;
    padding: 0 5px;
    border-radius: 50%;
    background: red;
    color: white;
}