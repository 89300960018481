@media print{
    @page {
        size: landscape;
    }
}

.barListContainer {
    overflow-y: scroll;
}

.barList {
    text-align: center;
    min-width: 1000px;
}

.barList > tr:last-child {
    border-bottom: #000 1px solid;
    padding-bottom: 1px;
}

.barList .small-column {
    width: 10%;
}

.barList .column {
  width: 15%;
}

.barList .small-column,
.barList .column {
    padding: .5rem;
    border-left: #000 1px solid;
    border-top: #000 1px solid;
}

.barList .small-column:last-child,
.barList .column:last-child {
    border-right: #000 1px solid;
}

.barList .header {
    background-color: lightgray;
}

.barList .header * {
    font-weight: bold;
}

.claimed {
    background-color: lightyellow;
}

.claimedOwn {
    background-color: lightgreen;
}
